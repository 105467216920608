<template>
  <div class="my-wrapper">
    <div class="my-header-wrapper">
      <div class="icon">
        <img :src="userIcon" alt="用户头像" />
      </div>
      <h3>{{ realName }}</h3>
      <span>用户名:{{ username }}</span>
    </div>
    <div class="my-list-wrapper">
      <ListItem title="设置" @handleListItemClick="handleSetClick('/set')">
        <i class="iconfont iconshezhi" slot="icon"></i>
        <i class="iconfont iconyou" slot="value"></i>
      </ListItem>
      <ListItem
        :title="wxStatus"
        :borderBottom="borderBottomStatus"
        @handleListItemClick="handleWXClick"
      >
        <i class="iconfont iconjiebangweixin" slot="icon"></i>
        <i class="iconfont iconyou" slot="value"></i>
      </ListItem>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import ListItem from '@/components/ListItem'
import userIcon from '@/assets/img/my/user-icon.png'
import { getInfo, getUnbindWechat } from '@/api/my'
import { is_weixn } from '@/utils/brower'
import { Notify } from 'vant'
import { prodUrl } from '@/config/env'
Vue.use(Notify)
export default {
  name: 'My',
  components: {
    ListItem,
  },
  data() {
    return {
      username: '',
      realName: '',
      borderBottomStatus: false,
      userIcon: userIcon,
      wxStatus: '解绑微信',
    }
  },
  mounted() {
    const code = this.getQueryString('code')
    const state = this.getQueryString('state')
    if (code && state === '234') {
      this.getUnbindWechat(code)
    }
    this.getInfoMyRequest()
  },
  methods: {
    ...mapActions('loading', ['setLoading']),
    ...mapActions('user', ['setToken', 'setUser']),
    handleSetClick(url) {
      this.$router.push(url)
    },
    /**
     * 微信绑定解绑
     */
    handleWXClick() {
      if (is_weixn()) {
        const redirect_uri = 'https://m.data.jiot.link/#/my'
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd879b4d063c99cfa&redirect_uri=${encodeURIComponent(
          redirect_uri
        )}&response_type=code&scope=snsapi_base&state=234&connect_redirect=1#wechat_redirect`
      } else {
        Notify({ type: 'danger', message: '只能微信浏览器才可以解绑' })
      }
    },
    /**
     * 获取url参数
     */
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    /**
     * 解绑微信
     */
    getUnbindWechat(code) {
      getUnbindWechat({
        code,
      }).then((res) => {
        if (res.retCode === 1) {
          Notify({ type: 'success', message: res.retMsg })
        } else {
          Notify({ type: 'danger', message: res.retMsg })
        }
        this.setUser(null)
        this.setToken(null)
        setTimeout(() => {
          // window.location.href = prodUrl + 'my'
          window.location.href = prodUrl
        }, 1000)
      })
    },
    /**
     * 获取我的信息
     */
    getInfoMyRequest() {
      this.setLoading(true)
      getInfo()
        .then((res) => {
          const { username, realName } = res
          this.realName = realName
          this.username = username
        })
        .finally(() => {
          this.setLoading(false)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.my-wrapper {
  @include wh(100%, 100%);
  background-color: #f1f1f1;
  .my-header-wrapper {
    width: 100%;
    height: 10rem;
    background-color: white;
    text-align: center;
    box-sizing: border-box;
    padding-top: 2rem;
    .icon {
      @include wh(4rem, 4rem);
      background-color: #f2c345;
      border-radius: 5rem;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
    h3 {
      font-size: 1rem;
      margin: 0.3rem;
    }
    span {
      background-color: #f2c345;
      border-radius: 1rem;
      padding: 0.2rem 1rem;
      font-size: 0.7rem;
      color: white;
    }
  }
  .my-list-wrapper {
    margin-top: 1rem;
    @include wh(100%, 6rem);
    background-color: white;
    i.iconfont {
      font-size: 1rem;
      color: #4a8cff;
    }
    i.iconyou {
      color: #999999;
      font-size: 0.7rem;
    }
  }
}
</style>
